import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import data from './assets/moneyfile';
import questions from './assets/quizData';
import nextSound from './assets/sounds/nextsound.wav'
import wrongSound from './assets/sounds/wrongsound.wav'
import endofgame from './assets/sounds/endofgame.wav'
import Optionsbtn from './Optionsbtn';

const playNext = () => {
  new Audio(nextSound).play()
}
const playWrong = () => {
  new Audio(wrongSound).play()
}

const playEnd = () => {
  new Audio(endofgame).play()
}
export default function GamePage() {
  const navigate = useNavigate();
  const contextVal = useOutletContext();
  const [counter, setCounter] = useState(30);
  const [qizes, SetQizes] = useState('');
  const [answers, setAnswers] = useState([]);
  const [classname, setClassname] = useState('');
  const [selected, setSelected] = useState('');
  const [pause, setPause] = useState(1000);



  var timers = null;
  useEffect(() => {
    SetQizes(questions[contextVal.qnumber]);
    setAnswers(questions[contextVal.qnumber].choises);
    timers = counter > 0 && setInterval(() => setCounter(counter - 1), pause);
    return () => clearInterval(timers);
  }, [counter]);

  if (counter === 0 | contextVal.qnumber > 14 | contextVal.winAmount > 500001) {
    if (counter === 0) {
      playEnd()
    }
    setTimeout(() => {
      navigate('/resualts');
    }, 3000);
  }

  function checkAnswer(answer) {
    setSelected(answer)
    setPause(10000)                                /* set pause */
    setClassname('choise active');
    setTimeout(() => {
      if (answer.correct) {
        setClassname('choise correct');
        setTimeout(() => { playNext() }, 2000)
        setTimeout(() => {
          contextVal.setWinId((prvId) => prvId + 1);
          contextVal.setQnumber((prvNum) => prvNum + 1);
        }, 3000);
      } else {
        setClassname('choise wrong');
        setTimeout(() => { playWrong() }, 2000)
        setTimeout(() => {
          navigate('/resualts');
        }, 4000);
      }
    }, 2000)
  }
  useEffect(() => {
    contextVal.setWinAmount(
      data.find((item) => item.id === contextVal.winId).amount
    );
    setCounter(30);
    setPause(1000)           /* set pause */
  }, [contextVal.qnumber]);

  return (
    <div className="game-section">
      <Optionsbtn />
      <div className="name-bar">
        <div className="names">
          <div className="name-inner">
            <p className="name2"> Player Name : </p>
            <h3 className="name1">{contextVal.name}</h3>
          </div>
          <div className={counter < 15 ? "countdown endtime" : "countdown"}   >
            <h3> {counter} </h3>
          </div>
        </div>
      </div>
      <div className="sections">
        <div className="game-side">
          <div className="qestions">
            <h4 className={contextVal.classname}>
              {qizes.question}
            </h4>
          </div>
          <div className="answers">

            {answers.map((item) => {
              return (
                <button className={selected === item ? classname : 'choise'} onClick={() => checkAnswer(item)} key={item.text} > {item.text}
                </button>
              );
            })}

          </div>
        </div>
        <div className="money-side">
          {data.map((item) => (
            <div
              className={
                contextVal.qnumber + 1 === item.id
                  ? 'money-lists activeOne'
                  : 'money-lists'
              }
              key={item.id}
            >
              <div className="list-num"> {item.id} </div>
              <div className="prizes"> {item.amount} </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
