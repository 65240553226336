import React from 'react';
import './components/App.css';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import GamePage from './components/GamePage';
import ResualtPage from './components/ResualtPage';
import HomePage from './components/HomePage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="games" element={<GamePage />} />
          <Route path="resualts" element={<ResualtPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
