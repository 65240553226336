import React from 'react'
import fifty from './assets/icons/fifty.png'
import calls from './assets/icons/calls.png'
import frinds from './assets/icons/friends.png'
import rings from './assets/sounds/ringring.wav'

export default function Optionsbtn() {

  const playRing = () => {
    new Audio(rings).play()
  }

  return (
    <div className='options'>
      <button className="fifty-fifty">
        <img src={fifty} alt="" />
      </button>
      <button className="callfamily"
        onClick={() => playRing()} >
        <img src={calls} alt="" />
      </button>
      <button className="askfrieds">
        <img src={frinds} alt="" />
      </button>
    </div>
  )
}
