import { Outlet } from 'react-router-dom';
import React, { useState } from 'react';

export default function Layout() {
  const [name, setName] = useState('');
  const [winAmount, setWinAmount] = useState(100);
  const [winId, setWinId] = useState(1);
  const [timeout, setTimeout] = useState(false);
  const [qnumber, setQnumber] = useState(0);
  
  const contextItems = {
    name: name,
    setname: setName,
    winAmount: winAmount,
    setWinAmount: setWinAmount,
    winId: winId,
    setWinId: setWinId,
    timeout: timeout,
    setTimeout: setTimeout,
    qnumber: qnumber,
    setQnumber:setQnumber
    
  };
  return (
    <div className="outlets">
      <h1>Who want to be a Millionaire ?</h1>
      <Outlet context={contextItems} />
    </div>
  );
}
