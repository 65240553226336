import React, { useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

export default function HomePage(props) {
  const [values, setValues] = useState('');
  const inputRef = useRef();
  const navigate = useNavigate();
  const context = useOutletContext();

  const handleStart = (e) => {
    e.preventDefault();
    context.setname(values);

    navigate('/games');
  };

  return (
    <div className="sections">
      <div className="welcome">
        <h2>Welcome !</h2>
        <form className="welcome-form" onSubmit={handleStart}>
          <input
            autoFocus
            type="text"
            className="user-name"
            ref={inputRef}
            value={values}
            placeholder='Enter your name'
            onChange={(e) => setValues(e.target.value)}
          />
          <button className="start-btn">Start Game</button>
        </form>
      </div>
    </div>
  );
}
