const questions = [
  {
    id: 1,
    question: 'What was the first state?',
    choises: [
      { text: 'Virginia', correct: false },
      { text: 'New York', correct: false },
      { text: 'Delaware', correct: true },
      { text: 'Wisconsin', correct: false },
    ],
  },
  {
    id: 2,
    question: 'What U.S state grows Coffee beans?',
    choises: [
      { text: 'Michigan', correct: false },
      { text: 'Hawaii', correct: true },
      { text: 'Wisconsin', correct: false },
      { text: 'Washington', correct: false },
    ],
  },
  {
    id: 3,
    question: 'What year is the first "Batman" comic book published?',
    choises: [
      { text: '1969', correct: false },
      { text: '1898', correct: false },
      { text: '1945', correct: false },
      { text: '1939', correct: true },
    ],
  },
  {
    id: 4,
    question: 'What percentage of wildlife is found in the Ocean?',
    choises: [
      { text: '94%', correct: true },
      { text: '50%', correct: false },
      { text: '35%', correct: false },
      { text: '75%', correct: false },
    ],
  },
  {
    id: 5,
    question: 'What animal breathes through its butt?',
    choises: [
      { text: 'Kangaroo', correct: false },
      { text: 'Pig', correct: false },
      { text: 'Camel', correct: false },
      { text: 'Turtle', correct: true },
    ],
  },
  {
    id: 6,
    question: 'What is the nearest planet to the sun?',
    choises: [
      { text: 'Earth', correct: false },
      { text: 'Jupiter', correct: false },
      { text: 'Mercury', correct: true },
      { text: 'Mars', correct: false },
    ],
  },
  {
    id: 7,
    question: 'How many hearts does an Octopus have?',
    choises: [
      { text: '3 Hearts', correct: true },
      { text: '1 Heart', correct: false },
      { text: '4 Hearts', correct: false },
      { text: '2 Hearts', correct: false },
    ],
  },
  {
    id: 8,
    question: 'What is the hottest planet in the solar system?',
    choises: [
      { text: 'Earth', correct: false },
      { text: 'Jupiter', correct: false },
      { text: 'Mars', correct: false },
      { text: 'Venus', correct: true },
    ],
  },
  {
    id: 9,
    question: 'Who was the youngest US president?',
    choises: [
      { text: 'Obama', correct: false },
      { text: 'T. Roosevelt', correct: true },
      { text: 'J.F. Kennedy', correct: false },
      { text: 'Clinton', correct: false },
    ],
  },
  {
    id: 10,
    question: 'When was the first ipod released?',
    choises: [
      { text: '2001', correct: true },
      { text: '1964', correct: false },
      { text: '2011', correct: false },
      { text: '2009', correct: false },
    ],
  },
  {
    id: 11,
    question: 'The first Web Browser invented in 1990?',
    choises: [
      { text: 'Netscape', correct: false },
      { text: 'Explore', correct: false },
      { text: 'Nexus', correct: true },
      { text: 'Chrome', correct: false },
    ],
  },
  {
    id: 12,
    question: 'Computer hard Disk first introduced in 1956 by?',
    choises: [
      { text: 'Microsoft', correct: false },
      { text: 'IBM', correct: true },
      { text: 'Apple', correct: false },
      { text: 'Dell', correct: false },
    ],
  },
  {
    id: 13,
    question: 'The totol number of function key in your keyboard?',
    choises: [
      { text: '26', correct: false },
      { text: '10', correct: false },
      { text: '6', correct: false },
      { text: '12', correct: true },
    ],
  },
  {
    id: 14,
    question: 'Which browser is developed by Google?',
    choises: [
      { text: 'Firefox', correct: false },
      { text: 'Safri', correct: false },
      { text: 'Chrome', correct: true },
      { text: 'Netscape', correct: false },
    ],
  },
  {
    id: 15,
    question: 'Who is the founder of Bluetooth?',
    choises: [
      { text: 'IBM', correct: false },
      { text: 'Ericsson', correct: true },
      { text: 'Dell', correct: false },
      { text: 'Apple', correct: false },
    ],
  },
];

export default questions;
