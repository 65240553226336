import React from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'
import clapping from './assets/sounds/clapping.wav'


export default function ResualtPage() {
  const contextVal = useOutletContext();
  const navigate = useNavigate();

  const playClap = () => {
    new Audio(clapping).play()
  }

  if (contextVal.winAmount > 17000) {
    playClap()
  }

  const startovergame = () => {
    navigate('/');
    contextVal.setname('');
    contextVal.setWinAmount(0);
    contextVal.setQnumber(0);
    contextVal.setWinId(1);
  };
  return (
    <div className="sections resualtPage">
      <Confetti width={window.innerWidth} gravity={0.025} />

      <div className="final">
        <div className="final-name">
          <h1>{contextVal.name}</h1>
        </div>
        <div className="final-resualt">
          <h2>You Won </h2>
          <h1>{contextVal.winAmount.toLocaleString("en-US", { style: "currency", currency: "USD" })} </h1>
        </div>
        <div className="final-button">
          <button className="final-btn" onClick={() => startovergame()}>
            Start Over
          </button>
        </div>
      </div>
    </div>
  );
}
